namespace $ {
	export class $hedgedogs_main extends $mol_view {
		
		/**
		 * ```tree
		 * title \Сказочные Ежики - хакатоним
		 * ```
		 */
		title() {
			return "Сказочные Ежики - хакатоним"
		}
		
		/**
		 * ```tree
		 * sub / <= Container
		 * ```
		 */
		sub() {
			return [
				this.Container()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * theme \$mol_theme_light
		 * ```
		 */
		theme() {
			return "$mol_theme_light"
		}
		
		/**
		 * ```tree
		 * Logo $mol_image2 links / \hedgedogs/main/logo.png
		 * ```
		 */
		@ $mol_mem
		Logo() {
			const obj = new this.$.$mol_image2()
			
			obj.links = () => [
				"hedgedogs/main/logo.png"
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Title $mol_text text \# Сказочные Ежики
		 * ```
		 */
		@ $mol_mem
		Title() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => "# Сказочные Ежики"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Contact $mol_link_iconed uri \https://t.me/amigun
		 * ```
		 */
		@ $mol_mem
		Contact() {
			const obj = new this.$.$mol_link_iconed()
			
			obj.uri = () => "https://t.me/amigun"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Column $mol_list rows /
		 * 	<= Logo
		 * 	<= Title
		 * 	<= Contact
		 * ```
		 */
		@ $mol_mem
		Column() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Logo(),
				this.Title(),
				this.Contact()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Container $mol_view sub / <= Column
		 * ```
		 */
		@ $mol_mem
		Container() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.Column()
			] as readonly any[]
			
			return obj
		}
	}
	
}

