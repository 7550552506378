namespace $ {
	export class $mol_image2 extends $mol_view {
		
		/**
		 * ```tree
		 * links /
		 * ```
		 */
		links() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * aspect 1
		 * ```
		 */
		aspect() {
			return 1
		}
		
		/**
		 * ```tree
		 * sub / <= Content
		 * ```
		 */
		sub() {
			return [
				this.Content()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * height \100%
		 * ```
		 */
		height() {
			return "100%"
		}
		
		/**
		 * ```tree
		 * background \
		 * ```
		 */
		background() {
			return ""
		}
		
		/**
		 * ```tree
		 * Content $mol_view style *
		 * 	paddingTop <= height
		 * 	backgroundImage <= background
		 * ```
		 */
		@ $mol_mem
		Content() {
			const obj = new this.$.$mol_view()
			
			obj.style = () => ({
				paddingTop: this.height(),
				backgroundImage: this.background()
			} as Record< string, any >)
			
			return obj
		}
	}
	
}

